
body {
  margin: 0;
  line-height: normal;
  font-family: var(--font-montserrat);
}

:root {
  /* fonts */
  --font-montserrat: Montserrat;
  --font-orbitron: Orbitron;

  /* font sizes */
  --font-size-base: 16px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #051f28;
  --secondary-color: #0d99ff;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-81xl: 100px;
}

.picture-container {
  height: 100vh;
  background: url('../public/unsplashjvq0q5ikemm@2x.png') center/cover no-repeat rgba(33, 65, 69, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;

}
.image-conf{width: 100% !important;}
.image-container {
  
  position: relative;
  height: 100vh; /* Set the height as needed */
  background: url('../public/unsplashjvq0q5ikemm@2x.png') center/cover no-repeat rgba(33, 65, 69, 0.6);
}

/* Overlay styles */
.overlay-finish {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 65, 69, 0.6); /* Adjust the overlay color and transparency as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}
.additional-text {
  color: #fff; /* Set the text color */
  text-align: center;
  font-size: 18px; /* Set the font size as needed */
  margin-top: 10px; /* Adjust spacing as needed */
}
/* Text styles */
.overlay-text {
  color: #fff; /* Set the text color */
  text-align: center;
}
.signup-form-container {
  font-family: var(--font-montserrat);
  align-items: center;
  justify-content: center;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(33, 65, 69, 0.6);
    border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;

    }
.signup-form-container .form-control {
  width: 100%; /* Set to 100% to take the full width of the container */
} 
@media (max-width: 768px) {
  .picture-container,
  .signup-form-container {
    border-radius: 0px; /* Remove border radius on small screens */

  }

  .overlay {
    border-start-end-radius: 0px; /* Remove border radius on small screens */

    border-end-start-radius: 100px; /* Remove border radius on small screens */
    border-end-end-radius: 100px; /* Remove border radius on small screens */
  }

  .picture-container {
    background-size: cover; /* Maintain background cover */
    background-position: center center;
    border-end-start-radius: 100px; /* Remove border radius on small screens */
    border-end-end-radius: 100px; /* Remove border radius on small screens */


  }
}
.otp-input {
  display: flex;
  justify-content: space-between;
}

.otp-input input {
  width: 48px;
  text-align: center;
  height: 64px;
  margin-left: 10px;
}
.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-left: 30px;
}

.progress-step {
  position: relative;
  width: 21px;
  height: 21px;
  border: 2px solid #ffffff;
  background-color: #05202B;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: bold;
  color: #ffffff;
}
.active-outer {
  border: 2px solid #007bff;
}
.disable-outer {
  border: 2px solid rgb(91, 91, 91);
}

.inner-circle {
  width: 11px;
  height: 11px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
}

.progress-bar {
  width: 1px;
  height: 80px;
  background-color: #A5A5A5;
  margin-bottom: 10px;

}
.steps{
    margin-left: 130px;
}
.linkk{
  color: white !important;
}
.disable-text{
  color: #A5A5A5;
}
.sidebar {
  height: 100vh;
  background-color: #05202B; /* Dark blue color */
  color: #fff; /* Text color */
}

.sidebar a {
  color: #adb5bd; /* Link color */
}

.sidebar a:hover {
  color: #fff; /* Hovered link color */
}
.active{
  background-color: #007bff;

}
.disable{
  background-color: #636363;
  color: #051f28;
}
.logo{
  margin-top: 24px;
  font-family: var(--font-orbitron);
}
.property-image{
  height: 150px;
}
.property-container {
  display: flex;
  align-items: center;
}

.property-image-finish {
  height: 150px;

}
small{
  font-size: 1ch;
}
.options{
  padding-left: 20px;
  padding-right: 20px;

  border: 1px solid black;
}
.default-link{
  background-color: rgb(255, 255, 255); /* Transparent blue background */
  border: 0px;
  text-decoration: none;
  color: var(--color-gray-100);
}
.default-link:hover{
  text-decoration: underline;

  color: var(--color-gray-100);
}
.mobile-navbar {
  display: none; /* Initially hide the navigation bar */
}
@media (max-width: 767px) {
  .picture-container {
    /* Adjust the width or max-width property based on your design requirements */
    max-width: 100%;
    height: 450px;
    margin-bottom: 30px;
  }
  .progress-bar {
    margin-left: 25px;
    
  }
  .progress-step {
    margin-left: 15px;
    
  }
  .mobile-navbar {
    background-color: #05202B !important;

    display: block; /* Show the navigation bar on smaller screens */
  }
  .property-container {
    display: flex;
    align-items: left;
  }
  .choice{
    width: 60% !important;
    max-width: 100% !important;
  }
  .selectt {
    width: 100% !important;
    max-width:  100% !important;

  }

  
  /* Optional: Center the content horizontally */
  .selectt {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
